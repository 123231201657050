import { graphql, useStaticQuery } from 'gatsby'

export const useContactBannerData = () => {
  const { contactBanner } = useStaticQuery(graphql`
    query SiteContactBanner {
      contactBanner: markdownRemark(
        frontmatter: { templateKey: { eq: "settings-contact-banner" } }
      ) {
        frontmatter {
          isContactBannerEnabled
          phoneHeading
          phoneNumber
          phoneOpeningHours
          phoneLink
          phoneLinkText
          chatHeading
          chatOpeningHours
          chatLink
          chatLinkText
        }
      }
    }
  `)

  return contactBanner.frontmatter
}

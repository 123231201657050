import { graphql, useStaticQuery } from 'gatsby'

export const useFooterData = () => {
  const { footer } = useStaticQuery(graphql`
    query SiteFooter {
      footer: markdownRemark(
        frontmatter: { templateKey: { eq: "settings-footer" } }
      ) {
        frontmatter {
          title
          navcontact
        }
        html
      }
    }
  `)

  return footer
}

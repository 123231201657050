import React from 'react'

export const Logo = ({ dark, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="144"
      fill="none"
      viewBox="0 0 144 144">
      <path
        fill={dark ? '#F9F9F9' : '#1A1818'}
        d="M71.553 143.108c39.518 0 71.554-32.036 71.554-71.554S111.071 0 71.553 0-.001 32.036-.001 71.554s32.036 71.554 71.554 71.554z"></path>
      <path
        fill="#040404"
        d="M120.938 111.936l.123-.559.675.533-.798.026z"></path>
      <path
        fill="#28F9F9"
        d="M30.115 68.688c-1.353-2.765-3.049-5.42-3.98-8.32-1.467-4.565-2.487-9.28-3.57-13.961-1.4-6.058 1.84-11.549 7.547-13 5.656-1.433 11.117 1.935 12.782 7.924a48.18 48.18 0 011.685 6.827c.418 3.363 2.3 4.578 5.417 4.483 1.593.49 3.16 1.1 4.782 1.448 5.287 1.13 8.44 4.271 9.514 9.583.465 2.3 1.191 4.548 1.666 6.848.4 1.962 1.41 2.974 3.47 2.755 2.47.692 4.928 1.426 7.411 2.066a10.567 10.567 0 018.148 8.026c1.406 5.4 2.834 10.808 4.086 16.249 1.41 6.128-1.812 11.545-7.542 13-5.695 1.443-11.814-1.769-12.635-7.9-1.094-8.171-4.87-12.4-13.114-13.117-2.027-.175-3.916-1.949-5.87-2.987-1.244-3.652-2.858-7.228-3.619-10.978-.707-3.484-2.406-4.96-5.77-5.655a98.443 98.443 0 01-10.408-3.29z"></path>
      <path
        fill={dark ? '#1A1818' : '#F9F9F9'}
        d="M125.716 102.346a13.036 13.036 0 01-8.925 9.589l-3.364-.056-1.492-.364a11.014 11.014 0 01-7.121-8.974c-3.289-20.5-14.156-29.9-34.99-30.366-.177-7.142-.253-14.27 2.684-21.014 6.161 1.038 12.516 1.44 18.442 3.246 12.231 3.731 21.068 11.96 27.39 22.968l2.816 5.763.649 1.6 2.444 7.522.312 1.2.52 2.477.334 1.7.257 2.011.044 2.698z"></path>
      <path
        fill="#E9B442"
        d="M72.51 51.161c-2.938 6.744-2.863 13.873-2.685 21.014l-.4 1.1c-2.06.22-3.066-.792-3.47-2.755-.475-2.3-1.2-4.547-1.667-6.848-1.074-5.312-4.227-8.454-9.514-9.583-1.623-.346-3.189-.958-4.782-1.448 1.915-9.262 5.377-17.78 12.468-24.335a20.572 20.572 0 016.95-4.443 10.058 10.058 0 0111.96 5.054c2.306 4.3 1.19 9.173-2.2 12.9-2.557 2.812-4.465 6.21-6.66 9.344z"></path>
      <path
        fill="#F36CF8"
        d="M30.115 68.687a98.424 98.424 0 0010.407 3.3c3.365.694 5.065 2.171 5.771 5.655.761 3.75 2.375 7.327 3.618 10.978-3.177 6.446-8.227 9.968-15.54 9.431a14.925 14.925 0 01-13.427-11.775 15.2 15.2 0 016.872-16.208c.755-.481 1.532-.923 2.3-1.381z"></path>
      <path
        fill="#5E5E5E"
        d="M124.251 92.258l-2.444-7.522 2.444 7.522zM121.158 83.137l-2.816-5.763 2.816 5.763zM125.083 95.935l-.52-2.477.52 2.477zM125.673 99.644l-.257-2.01.257 2.01zM111.935 111.515l1.492.364-1.492-.364z"></path>
    </svg>
  )
}

import React from 'react'

export const Pattern = ({ color, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1979"
      height="596"
      fill="none"
      viewBox="0 0 1979 596">
      <path
        fill={color}
        d="M-35.484 317.701a31.926 31.926 0 01-32.935-5.223 31.921 31.921 0 01-10.312-31.712c1.091-4.583 27.873-112.856 124.642-162.278 66.232-33.83 146.951-31.48 239.905 6.99a31.906 31.906 0 0110.364 52.029 31.89 31.89 0 01-22.555 9.35 31.91 31.91 0 01-12.209-2.425c-74.665-30.9-137.252-34.036-186.018-9.363-70.492 35.686-91.885 119.753-92.094 120.6a31.754 31.754 0 01-18.788 22.032z"></path>
      <path
        fill={color}
        d="M58.788 207.794a339.023 339.023 0 01-38.991 13.325 31.903 31.903 0 01-17.094-61.472c53.389-14.85 88.282-39.2 103.706-72.392 21.967-47.281-1.152-100.4-1.394-100.926a31.91 31.91 0 01-.902-24.4 31.917 31.917 0 0116.616-17.892 31.904 31.904 0 0142.292 15.715c1.481 3.229 35.841 79.944 1.25 154.389-19.111 41.125-54.512 72.519-105.483 93.653z"></path>
      <path
        fill={color}
        d="M195.935 62.914a31.976 31.976 0 01-6 1.823c-3.484.7-86.046 16.482-150.534-34.306C-4.699-4.3-28.54-59.887-31.465-134.783a31.903 31.903 0 0163.755-2.49c2.153 55.128 17.7 94.585 46.2 117.274 41.04 32.666 98.5 22.25 99.078 22.14a31.9 31.9 0 0118.364 60.773h.003z"></path>
      <path
        fill={color}
        d="M-5.25 54.032a159.817 159.817 0 01-20.57 6.942c-54.282 14.293-113.018-.142-174.572-42.9a31.904 31.904 0 01-8-44.4 31.9 31.9 0 0144.4-8C-118.477-2.708-77.456 8.599-42.064-.72 8.355-13.998 34.479-65.704 34.73-66.22a31.901 31.901 0 0157.486 27.68C90.815-35.641 60.001 26.977-5.25 54.032zM411.856 506.81a31.806 31.806 0 01-24.855-.176c-4.327-1.868-106.359-46.916-138.359-150.756-21.9-71.077-5.671-150.178 48.249-235.106a31.902 31.902 0 1153.865 34.2c-43.449 68.437-57.292 129.711-41.142 182.119 23.428 76.022 101.868 110.62 102.66 110.96a31.894 31.894 0 0119.268 29.519 31.906 31.906 0 01-19.686 29.243v-.003zM244.881 549.732c-22.9 9.492-50.851 16.17-81.867 13.406-55.912-4.986-106.259-38.5-149.64-99.624a31.9 31.9 0 017.55-44.481 31.9 31.9 0 0144.48 7.55c31.933 44.99 66.514 69.535 102.788 72.958 52.219 4.925 94.651-35.2 95.073-35.61a31.898 31.898 0 0134.848-6.491 31.918 31.918 0 0110.265 7.04 31.916 31.916 0 019.068 22.67 31.9 31.9 0 01-9.618 22.443c-1.575 1.546-25.486 24.609-62.947 40.139z"></path>
      <path
        fill={color}
        d="M316.609 476.101a31.923 31.923 0 01-43.98-26.353c-5.395-54.9-23.237-93.378-53.028-114.35-42.89-30.2-99.643-16.416-100.211-16.272a31.9 31.9 0 01-38.891-22.868 31.902 31.902 0 0122.867-38.891c3.436-.9 84.926-21.52 152.293 25.387 46.065 32.074 73.138 86.162 80.466 160.755a31.919 31.919 0 01-19.516 32.592z"></path>
      <path
        fill={color}
        d="M104.921 420.621c-35.467 14.7-78.361 18.749-128.2 12.019a31.906 31.906 0 01-27.346-35.885 31.901 31.901 0 0135.885-27.346c54.672 7.385 96.219-1.124 123.483-25.294 39.252-34.792 38.9-93.191 38.892-93.777a31.903 31.903 0 0163.79-1.385c.085 3.553 1.4 87.6-59.747 142.366a163.318 163.318 0 01-46.757 29.302z"></path>
      <path
        fill={color}
        d="M87.718 632.419a31.907 31.907 0 01-34.9-51.917c38.817-39.2 57.04-77.5 54.163-113.819-4.14-52.289-50.98-87.173-51.451-87.519a31.915 31.915 0 01-12.837-20.77 31.904 31.904 0 0126.38-36.6 31.894 31.894 0 0123.763 5.61c2.886 2.07 70.781 51.629 77.683 133.425 4.721 55.936-19.625 111.3-72.36 164.564a31.798 31.798 0 01-10.441 7.026zM17.19 373.239a31.86 31.86 0 01-18.292 1.851c-3.488-.672-85.888-17.292-126.369-88.7-27.682-48.832-28.726-109.307-3.1-179.742a31.894 31.894 0 0116.493-18.004 31.897 31.897 0 0135.035 5.387 31.907 31.907 0 018.43 34.43c-18.86 51.845-19.4 94.25-1.594 126.038 25.633 45.762 82.767 57.86 83.341 57.976a31.878 31.878 0 0125.631 28.132 31.882 31.882 0 01-19.575 32.637v-.005zM346.674 159.274a31.927 31.927 0 01-43.249-36.936c1.094-4.584 27.875-112.856 124.644-162.28 66.232-33.828 146.949-31.477 239.905 6.992a31.9 31.9 0 11-24.4 58.954c-74.662-30.9-137.241-34.04-186.016-9.361-70.494 35.684-91.887 119.753-92.094 120.6a31.773 31.773 0 01-18.79 22.031z"></path>
      <path
        fill={color}
        d="M328.17 71.834a32 32 0 01-30.933-3.544c-2.888-2.069-70.79-51.617-77.707-133.412-4.73-55.933 19.605-111.3 72.331-164.575a31.896 31.896 0 0134.73-7.095 31.905 31.905 0 0110.618 51.978c-38.809 39.211-57.025 77.509-54.144 113.828 4.151 52.289 50.995 87.164 51.469 87.51a31.878 31.878 0 0113.052 29.523 31.88 31.88 0 01-19.417 25.788l.001-.001zM440.944 49.365a338.388 338.388 0 01-38.991 13.326A31.902 31.902 0 01384.859 1.22c53.389-14.85 88.282-39.2 103.706-72.393 21.969-47.279-1.152-100.4-1.392-100.927a31.903 31.903 0 0158.006-26.576c1.479 3.229 35.838 79.944 1.248 154.389-19.108 41.124-54.512 72.52-105.483 93.652zM794.014 348.381a31.807 31.807 0 01-24.855-.177c-4.329-1.865-106.361-46.913-138.361-150.756-21.904-71.076-5.671-150.176 48.245-235.106a31.901 31.901 0 1153.865 34.2c-43.449 68.437-57.29 129.709-41.14 182.117 23.428 76.022 101.868 110.621 102.657 110.96a31.898 31.898 0 0113.833 47.106 31.888 31.888 0 01-14.248 11.656h.004zM627.038 391.303c-22.9 9.492-50.851 16.17-81.867 13.406-55.912-4.984-106.256-38.5-149.64-99.624a31.903 31.903 0 0131.354-49.917 31.9 31.9 0 0120.676 12.987c31.933 44.989 66.517 69.535 102.788 72.958 52.219 4.926 94.65-35.2 95.075-35.61a31.89 31.89 0 0122.671-9.067c4.189.051 8.327.927 12.177 2.578a31.913 31.913 0 0117.053 17.475 31.901 31.901 0 01-7.341 34.679c-1.572 1.544-25.483 24.604-62.946 40.135z"></path>
      <path
        fill={color}
        d="M698.764 317.672a31.923 31.923 0 01-43.98-26.353c-5.395-54.9-23.235-93.379-53.026-114.35-42.893-30.2-99.646-16.416-100.213-16.272a31.9 31.9 0 11-16.022-61.76c3.436-.9 84.926-21.518 152.291 25.388 46.065 32.077 73.138 86.162 80.469 160.754a31.922 31.922 0 01-19.519 32.593z"></path>
      <path
        fill={color}
        d="M487.077 262.196c-35.467 14.7-78.361 18.747-128.2 12.017a31.897 31.897 0 01-21.104-12.279 31.898 31.898 0 016.038-44.709 31.898 31.898 0 0123.605-6.241c54.672 7.383 96.219-1.126 123.485-25.294 39.252-34.792 38.9-93.194 38.889-93.78a31.9 31.9 0 0131.203-32.587 31.908 31.908 0 0122.756 8.853 31.903 31.903 0 019.831 22.35c.085 3.553 1.4 87.601-59.747 142.365a163.308 163.308 0 01-46.756 29.305z"></path>
      <path
        fill={color}
        d="M469.874 473.992a31.905 31.905 0 01-38.704-11.673 31.908 31.908 0 013.804-40.246c38.817-39.2 57.04-77.5 54.161-113.816-4.14-52.291-50.978-87.173-51.449-87.521a31.906 31.906 0 01-12.398-33.207 31.912 31.912 0 0114.275-19.808 31.9 31.9 0 0135.423 1.254c2.888 2.07 70.781 51.63 77.685 133.425 4.719 55.935-19.627 111.3-72.362 164.564a31.773 31.773 0 01-10.435 7.028zM399.344 214.811a31.86 31.86 0 01-18.292 1.851c-3.488-.672-85.888-17.292-126.369-88.7-27.682-48.831-28.726-109.305-3.1-179.742a31.895 31.895 0 0116.493-18.004 31.897 31.897 0 0135.035 5.387 31.9 31.9 0 018.43 34.43c-18.859 51.845-19.4 94.251-1.592 126.038 25.631 45.763 82.767 57.86 83.339 57.977a31.883 31.883 0 0125.632 28.132 31.88 31.88 0 01-19.576 32.636v-.005zM728.83.847a31.925 31.925 0 01-43.247-36.937c1.092-4.584 27.873-112.854 124.642-162.279 66.236-33.832 146.951-31.478 239.905 6.993a31.876 31.876 0 0110.35 6.912 31.895 31.895 0 019.35 22.556c0 4.189-.82 8.338-2.42 12.209a31.923 31.923 0 01-6.92 10.352 31.867 31.867 0 01-22.55 9.35c-4.19.001-8.34-.823-12.21-2.425-74.665-30.9-137.243-34.04-186.018-9.364-70.494 35.685-91.887 119.754-92.094 120.6A31.777 31.777 0 01728.83.847z"></path>
      <path
        fill={color}
        d="M1176.17 189.951a31.856 31.856 0 01-12.45 2.441c-4.27-.03-8.49-.92-12.4-2.615-4.33-1.869-106.37-46.916-138.37-150.757-21.896-71.076-5.67-150.18 48.25-235.108a31.953 31.953 0 0119.98-14.035 31.935 31.935 0 0112.45-.291c4.13.722 8.07 2.249 11.61 4.494a32.006 32.006 0 019 8.594 31.815 31.815 0 015.03 11.386c.91 4.088 1.01 8.317.29 12.444a31.974 31.974 0 01-4.49 11.608c-43.45 68.437-57.29 129.708-41.14 182.119 23.43 76.019 101.87 110.618 102.65 110.96a31.919 31.919 0 0114.09 11.856 31.898 31.898 0 015.18 17.661 31.866 31.866 0 01-5.43 17.587 31.911 31.911 0 01-14.25 11.656zM1009.2 232.874c-22.904 9.492-50.856 16.172-81.869 13.405-55.911-4.984-106.258-38.5-149.64-99.624a31.9 31.9 0 0152.028-36.93c31.933 44.989 66.517 69.537 102.788 72.958 52.219 4.927 94.653-35.2 95.073-35.61a31.912 31.912 0 0145.12.551 31.866 31.866 0 019.06 22.671 31.902 31.902 0 01-9.61 22.443c-1.58 1.544-25.49 24.605-62.95 40.136z"></path>
      <path
        fill={color}
        d="M1080.92 159.241a31.923 31.923 0 01-28.62-2.113 31.866 31.866 0 01-10.55-10.297 31.886 31.886 0 01-4.81-13.943c-5.4-54.9-23.23-93.376-53.024-114.35-42.893-30.194-99.643-16.414-100.211-16.272a31.902 31.902 0 11-16.024-61.76c3.436-.9 84.926-21.518 152.289 25.388 46.07 32.076 73.14 86.162 80.47 160.755a31.93 31.93 0 01-4.38 19.516 31.933 31.933 0 01-15.14 13.076z"></path>
      <path
        fill={color}
        d="M869.234 103.767c-35.467 14.7-78.362 18.747-128.2 12.016a31.9 31.9 0 01-15.064-56.988 31.902 31.902 0 0123.605-6.24c54.67 7.382 96.214-1.125 123.483-25.295 39.252-34.793 38.9-93.192 38.891-93.78a31.901 31.901 0 0163.788-1.382c.087 3.55 1.4 87.6-59.748 142.364a163.27 163.27 0 01-46.755 29.305z"></path>
      <path
        fill={color}
        d="M852.033 315.563a31.908 31.908 0 01-34.9-51.919c38.818-39.2 57.04-77.5 54.163-113.818-4.143-52.29-50.98-87.172-51.452-87.518a31.9 31.9 0 1137.3-51.763c2.888 2.07 70.783 51.63 77.685 133.427 4.721 55.933-19.625 111.3-72.36 164.564a31.852 31.852 0 01-10.436 7.027zM781.504 56.38a31.873 31.873 0 01-18.294 1.852c-3.486-.672-85.885-17.292-126.369-88.7-27.682-48.834-28.723-109.307-3.1-179.745a31.896 31.896 0 0128.583-20.965 31.901 31.901 0 0131.377 42.778c-18.862 51.845-19.4 94.253-1.594 126.038 25.631 45.763 82.767 57.86 83.341 57.977a31.886 31.886 0 0125.628 28.131 31.876 31.876 0 01-19.572 32.636v-.001zM1558.33 31.527a31.827 31.827 0 01-24.86-.176c-4.32-1.868-106.36-46.916-138.36-150.756-21.9-71.077-5.67-150.177 48.25-235.106a31.953 31.953 0 0119.98-14.035 31.915 31.915 0 0112.45-.29 31.87 31.87 0 0111.6 4.493 31.91 31.91 0 019.01 8.594 31.815 31.815 0 015.03 11.386c.91 4.089 1.01 8.317.29 12.444a31.814 31.814 0 01-4.5 11.608c-43.44 68.437-57.28 129.711-41.13 182.119 23.42 76.02 101.86 110.618 102.65 110.96a31.84 31.84 0 0114.08 11.856 31.833 31.833 0 015.19 17.663 31.867 31.867 0 01-5.43 17.587 31.961 31.961 0 01-14.25 11.656v-.003zM1391.35 74.445c-22.89 9.49-50.85 16.17-81.86 13.4-55.91-4.984-106.26-38.5-149.64-99.622a31.873 31.873 0 01-5.44-23.804 31.884 31.884 0 0112.99-20.677 31.885 31.885 0 0123.8-5.438 31.92 31.92 0 0120.68 12.987c31.93 44.989 66.51 69.537 102.78 72.958 52.22 4.925 94.66-35.2 95.08-35.608 3-2.926 6.54-5.233 10.43-6.79a31.916 31.916 0 0112.24-2.279c4.19.052 8.32.927 12.17 2.577a31.907 31.907 0 0117.06 17.474 32.035 32.035 0 012.28 12.237 31.89 31.89 0 01-2.58 12.178 31.868 31.868 0 01-7.04 10.265c-1.57 1.549-25.48 24.612-62.95 40.142z"></path>
      <path
        fill={color}
        d="M1463.08.815a31.94 31.94 0 01-28.62-2.116 31.877 31.877 0 01-10.55-10.296 31.882 31.882 0 01-4.81-13.941c-5.4-54.905-23.24-93.379-53.03-114.352-42.89-30.193-99.64-16.413-100.21-16.272a31.905 31.905 0 01-24.18-3.379 31.849 31.849 0 01-9.29-8.281 31.77 31.77 0 01-5.42-11.207 31.96 31.96 0 013.38-24.181 31.91 31.91 0 018.28-9.293 31.92 31.92 0 0111.21-5.416c3.43-.9 84.93-21.521 152.29 25.388 46.07 32.074 73.14 86.162 80.47 160.752a31.934 31.934 0 01-4.38 19.517A31.936 31.936 0 011463.08.815zM1234.19 157.133a31.896 31.896 0 01-21.43 1.073 31.897 31.897 0 01-17.27-12.748 31.93 31.93 0 01-5.29-20.8 31.97 31.97 0 019.09-19.444c38.82-39.2 57.04-77.5 54.16-113.817-4.14-52.289-50.98-87.173-51.45-87.519a31.938 31.938 0 01-12.84-20.77c-1.35-8.352.67-16.9 5.61-23.764a31.911 31.911 0 0144.54-7.228c2.88 2.07 70.78 51.63 77.68 133.427 4.72 55.933-19.63 111.3-72.36 164.564a31.892 31.892 0 01-10.44 7.026zM1616.35-1.297a31.922 31.922 0 01-21.44 1.073 31.907 31.907 0 01-17.26-12.746 31.931 31.931 0 01-5.29-20.801 31.858 31.858 0 019.09-19.443c38.81-39.205 57.04-77.5 54.16-113.819-4.14-52.29-50.98-87.173-51.45-87.519a31.9 31.9 0 1137.3-51.761c2.89 2.071 70.78 51.628 77.69 133.425 4.72 55.936-19.63 111.3-72.36 164.564a31.869 31.869 0 01-10.44 7.027zM1934.46 332.071c-63.24 26.218-138.71 21.521-224.64-14.043a31.895 31.895 0 01-17.27-17.26 31.929 31.929 0 01-.01-24.417 31.92 31.92 0 0117.27-17.27 31.884 31.884 0 0124.41-.007c74.66 30.9 137.24 34.04 186.02 9.364 70.49-35.687 91.88-119.753 92.09-120.6a31.905 31.905 0 0126.04-24.058 31.911 31.911 0 0123.74 5.701 31.884 31.884 0 018.44 9.141 31.793 31.793 0 014.31 11.679c.66 4.137.49 8.363-.49 12.437-1.09 4.587-27.87 112.857-124.64 162.281a216.53 216.53 0 01-15.27 7.052z"></path>
      <path
        fill={color}
        d="M1873.86 500.016a31.988 31.988 0 01-23.9.177 31.96 31.96 0 01-17.34-16.436c-1.48-3.229-35.84-79.946-1.25-154.389 23.65-50.906 72.25-86.9 144.47-106.98a31.907 31.907 0 0124.24 2.958 31.85 31.85 0 019.43 8.119 31.713 31.713 0 015.61 11.111 31.909 31.909 0 01.94 12.413 31.88 31.88 0 01-12.01 21.259 31.958 31.958 0 01-11.11 5.611c-53.16 14.78-87.98 38.99-103.5 71.949-22.35 47.457 1.01 100.98 1.25 101.516a31.836 31.836 0 012.86 12.099c.16 4.182-.52 8.354-1.98 12.275a31.858 31.858 0 01-16.63 17.844c-.36.168-.72.326-1.08.474z"></path>
      <path
        fill={color}
        d="M2007.44 609.014a31.894 31.894 0 01-15.02 2.296 31.942 31.942 0 01-14.39-4.913 31.941 31.941 0 01-10.48-11.008c-2.56-4.461-4-9.472-4.2-14.609-2.15-55.128-17.7-94.586-46.2-117.275-41.04-32.666-98.5-22.25-99.08-22.141a31.895 31.895 0 01-23.94-4.796 31.901 31.901 0 01-13.54-20.319 31.9 31.9 0 014.8-23.941 31.904 31.904 0 0120.32-13.538c3.48-.7 86.04-16.485 150.53 34.308 44.1 34.732 67.94 90.317 70.87 165.213a31.988 31.988 0 01-5.01 18.448 31.918 31.918 0 01-14.66 12.275z"></path>
      <path
        fill={color}
        d="M2190.08 481.101a31.872 31.872 0 01-30.45-3.262c-45.51-31.615-86.53-42.923-121.93-33.605-50.42 13.282-76.54 64.986-76.79 65.5a31.915 31.915 0 01-18.2 16.274 31.902 31.902 0 01-24.38-1.365 31.907 31.907 0 01-16.28-18.204 31.93 31.93 0 011.37-24.379c1.54-3.2 38.66-78.625 118.03-99.526 54.29-14.293 113.02.141 174.58 42.906a31.917 31.917 0 0110.81 12.945 31.983 31.983 0 012.71 16.65 31.889 31.889 0 01-6.17 15.701 31.853 31.853 0 01-13.31 10.365h.01zM1684.06 335.1a31.897 31.897 0 01-36.65-8.924 31.86 31.86 0 01-7.42-18.406 31.893 31.893 0 014.89-19.232c43.45-68.437 57.29-129.71 41.14-182.119-23.42-76.02-101.86-110.62-102.65-110.96a31.885 31.885 0 01-17.01-17.514 31.867 31.867 0 01-2.26-12.242c.07-4.19.95-8.325 2.61-12.172a31.979 31.979 0 017.06-10.248 31.99 31.99 0 0110.45-6.765 31.902 31.902 0 0124.42.355C1612.97-61.26 1715-16.211 1747 87.629c21.9 71.076 5.67 150.177-48.25 235.106a31.825 31.825 0 01-14.69 12.365zM1968.44 27.946a31.907 31.907 0 01-21.03 1.169 31.952 31.952 0 01-17.18-12.19c-31.93-44.99-66.51-69.537-102.78-72.958-52.22-4.927-94.66 35.2-95.08 35.608-3 2.926-6.54 5.233-10.43 6.789a31.916 31.916 0 01-24.42-.298 31.931 31.931 0 01-17.05-17.474 31.89 31.89 0 01.3-24.415 31.868 31.868 0 017.04-10.265c2.53-2.487 63.05-60.83 144.81-53.541 55.91 4.984 106.26 38.5 149.64 99.623a31.913 31.913 0 014.63 27.302 31.882 31.882 0 01-6.87 12.43 31.879 31.879 0 01-11.58 8.221v-.001z"></path>
      <path
        fill={color}
        d="M1896.44 184.74c-1.36.56-2.75 1.028-4.17 1.4-3.43.9-84.92 21.519-152.29-25.388-46.06-32.076-73.14-86.163-80.47-160.757a31.893 31.893 0 017.1-23.365 31.87 31.87 0 0121.53-11.505 31.918 31.918 0 0123.37 7.094 31.905 31.905 0 0111.5 21.537c5.42 55.152 23.4 93.72 53.43 114.635 42.79 29.789 99.1 16.174 99.66 16.029a31.897 31.897 0 0138.37 20.646 31.915 31.915 0 01-1.18 23.394 31.906 31.906 0 01-16.85 16.278v.002z"></path>
      <path
        fill={color}
        d="M1828.37 223.332a31.949 31.949 0 01-29.66-2.755 31.861 31.861 0 01-10.46-11.22 31.891 31.891 0 01-4.03-14.805c-.09-3.552-1.4-87.6 59.75-142.365 41.81-37.452 100.68-51.352 174.95-41.321a31.877 31.877 0 0121.11 12.279 31.88 31.88 0 016.24 23.605 31.831 31.831 0 01-4.04 11.773 31.983 31.983 0 01-8.24 9.331 31.877 31.877 0 01-23.6 6.24c-54.68-7.382-96.22 1.123-123.49 25.295-39.25 34.793-38.9 93.192-38.89 93.779a31.819 31.819 0 01-5.14 18.127 31.825 31.825 0 01-14.5 12.037z"></path>
      <path
        fill={color}
        d="M1933.74 119.649a31.986 31.986 0 01-15.92 2.242 31.994 31.994 0 01-15.01-5.786c-2.89-2.071-70.79-51.63-77.69-133.427-4.72-55.933 19.63-111.3 72.36-164.564a31.898 31.898 0 0122.51-9.457 31.913 31.913 0 0122.61 9.232 31.897 31.897 0 019.45 22.511 31.84 31.84 0 01-9.23 22.605c-38.82 39.2-57.04 77.5-54.16 113.819 4.14 52.289 50.98 87.173 51.45 87.518a31.82 31.82 0 0110.54 12.987 31.908 31.908 0 012.51 16.535c-.65 5.637-2.79 10.999-6.2 15.531a31.932 31.932 0 01-13.22 10.254zM2108.44 355.433a31.904 31.904 0 01-34.27-6.424c-4.47-4.28-7.6-9.77-9.01-15.8a31.93 31.93 0 011.09-18.155c18.86-51.845 19.4-94.251 1.6-126.038-25.64-45.765-82.77-57.86-83.35-57.977a31.92 31.92 0 01-20.34-13.497 32.059 32.059 0 01-4.81-11.484 31.99 31.99 0 01-.04-12.447 31.954 31.954 0 014.73-11.516 31.757 31.757 0 018.77-8.832 31.888 31.888 0 0123.93-4.844c3.49.672 85.89 17.294 126.37 88.705 27.68 48.832 28.72 109.306 3.1 179.743a31.84 31.84 0 01-17.77 18.566zM1552.3 490.501c-63.24 26.219-138.71 21.521-224.63-14.043a31.905 31.905 0 01-10.36-6.913 31.857 31.857 0 01-6.91-10.348 31.967 31.967 0 01-2.44-12.207c0-4.19.83-8.338 2.43-12.209a31.884 31.884 0 0117.26-17.27 31.895 31.895 0 0124.42-.007c74.66 30.9 137.24 34.04 186.01 9.364 70.5-35.687 91.89-119.754 92.1-120.6a31.971 31.971 0 015.21-11.304 31.909 31.909 0 0120.82-12.755c4.14-.655 8.36-.488 12.44.49a31.918 31.918 0 0119.75 14.353 31.784 31.784 0 014.3 11.678c.66 4.138.49 8.364-.49 12.438-1.09 4.584-27.87 112.856-124.64 162.279-5 2.559-10.09 4.91-15.27 7.054z"></path>
      <path
        fill={color}
        d="M1693.34 680.233a31.922 31.922 0 01-21.44 1.077 31.926 31.926 0 01-17.27-12.744 31.93 31.93 0 01-5.29-20.8 31.973 31.973 0 019.09-19.445c38.81-39.212 57.02-77.509 54.14-113.829-4.15-52.287-50.99-87.164-51.47-87.51a31.97 31.97 0 01-8.48-9.107 32.12 32.12 0 01-4.36-11.662c-.67-4.135-.52-8.362.44-12.44a32.036 32.036 0 015.17-11.324c2.45-3.4 5.55-6.283 9.11-8.486a31.85 31.85 0 0111.66-4.355c4.14-.671 8.36-.522 12.44.44s7.93 2.718 11.32 5.167c2.89 2.068 70.79 51.617 77.7 133.412 4.74 55.934-19.6 111.305-72.33 164.575a31.745 31.745 0 01-10.43 7.031zM1491.7 658.447a31.982 31.982 0 01-41.24-16.261c-1.48-3.229-35.84-79.945-1.25-154.387 23.65-50.906 72.26-86.906 144.48-106.981a31.86 31.86 0 0112.41-.931c4.16.507 8.18 1.829 11.82 3.89a31.87 31.87 0 0115.05 19.23 31.903 31.903 0 01-22.19 39.283c-53.15 14.778-87.97 38.987-103.5 71.949-22.35 47.457 1.01 100.981 1.25 101.516a31.848 31.848 0 012.87 12.1 31.72 31.72 0 01-1.99 12.275 31.686 31.686 0 01-6.54 10.578 31.772 31.772 0 01-10.09 7.266c-.35.163-.72.323-1.08.473z"></path>
      <path
        fill={color}
        d="M1625.29 767.445a31.936 31.936 0 01-29.42-2.615 31.956 31.956 0 01-10.48-11.01c-2.56-4.461-4-9.473-4.2-14.61-2.16-55.129-17.7-94.586-46.2-117.274-41.04-32.666-98.5-22.25-99.08-22.142a31.908 31.908 0 01-23.94-4.796 31.87 31.87 0 01-8.79-8.813 31.933 31.933 0 01-4.75-11.506c-.81-4.11-.8-8.34.02-12.448a31.894 31.894 0 014.78-11.493 31.936 31.936 0 018.81-8.789 31.945 31.945 0 0111.51-4.748c3.48-.7 86.04-16.486 150.53 34.305 44.1 34.732 67.94 90.319 70.87 165.214a31.993 31.993 0 01-5.01 18.448 31.96 31.96 0 01-14.65 12.277z"></path>
      <path
        fill={color}
        d="M1807.92 639.53a31.872 31.872 0 01-30.45-3.262c-45.51-31.617-86.53-42.923-121.92-33.6-50.43 13.281-76.55 64.986-76.8 65.5a31.84 31.84 0 01-7.48 9.946 31.902 31.902 0 01-35.1 4.958 31.908 31.908 0 01-9.94-7.483 31.713 31.713 0 01-6.33-10.721 31.87 31.87 0 01-1.74-12.324 31.772 31.772 0 013.11-12.053c1.54-3.2 38.66-78.625 118.03-99.527 54.28-14.293 113.02.141 174.58 42.906a31.917 31.917 0 0110.81 12.945 31.877 31.877 0 012.7 16.65 31.871 31.871 0 01-6.16 15.701 31.916 31.916 0 01-13.31 10.365v-.001zM1301.9 493.53a31.903 31.903 0 01-36.64-8.928 31.85 31.85 0 01-7.42-18.402 31.954 31.954 0 014.89-19.228c43.45-68.437 57.29-129.71 41.14-182.119-23.43-76.019-101.87-110.62-102.66-110.959a31.978 31.978 0 01-10.25-7.066 32.09 32.09 0 01-6.76-10.449c-3.12-7.864-3-16.646.36-24.415a31.9 31.9 0 0141.93-16.657c4.32 1.868 106.35 46.915 138.35 150.756 21.9 71.077 5.68 150.178-48.24 235.106a31.774 31.774 0 01-14.7 12.361zM1586.29 186.375a31.937 31.937 0 01-21.04 1.169 31.951 31.951 0 01-17.18-12.192c-31.93-44.989-66.51-69.535-102.78-72.958-52.22-4.927-94.65 35.2-95.08 35.61-3 2.926-6.54 5.233-10.43 6.789a31.914 31.914 0 01-24.42-.298 31.932 31.932 0 01-17.05-17.474 32.037 32.037 0 01-2.28-12.238c.05-4.189.93-8.327 2.58-12.177a31.864 31.864 0 017.04-10.265c2.54-2.488 63.05-60.83 144.81-53.543 55.91 4.986 106.26 38.5 149.64 99.624a31.908 31.908 0 014.63 27.301 32.01 32.01 0 01-6.87 12.429 31.894 31.894 0 01-11.58 8.223h.01z"></path>
      <path
        fill={color}
        d="M1514.29 343.17a32.412 32.412 0 01-4.17 1.4c-3.43.9-84.92 21.521-152.29-25.386-46.07-32.076-73.14-86.161-80.47-160.756-.41-4.169.01-8.379 1.22-12.388a31.947 31.947 0 015.88-10.976 31.726 31.726 0 019.62-7.895 31.9 31.9 0 0124.3-2.387 31.933 31.933 0 0110.98 5.87 31.954 31.954 0 017.89 9.624 31.807 31.807 0 013.61 11.913c5.42 55.152 23.4 93.72 53.43 114.632 42.79 29.792 99.1 16.177 99.66 16.031a31.914 31.914 0 0123.26 2.753 31.932 31.932 0 0115.11 17.895 31.909 31.909 0 01-1.19 23.391 31.86 31.86 0 01-16.84 16.278v.001z"></path>
      <path
        fill={color}
        d="M1446.22 381.761a31.949 31.949 0 01-29.66-2.755 31.997 31.997 0 01-10.47-11.22 32.004 32.004 0 01-4.02-14.805c-.09-3.553-1.41-87.6 59.74-142.364 41.82-37.452 100.68-51.352 174.96-41.321a31.881 31.881 0 0121.1 12.279 31.859 31.859 0 016.24 23.605 31.89 31.89 0 01-12.28 21.104 31.893 31.893 0 01-23.6 6.241c-54.67-7.383-96.22 1.122-123.48 25.294-39.26 34.793-38.9 93.192-38.89 93.777a31.894 31.894 0 01-5.14 18.127 31.821 31.821 0 01-14.5 12.038z"></path>
      <path
        fill={color}
        d="M1551.59 278.079a32.03 32.03 0 01-15.93 2.242 31.997 31.997 0 01-15.01-5.788c-2.89-2.071-70.78-51.628-77.68-133.425-4.73-55.935 19.62-111.3 72.36-164.564a31.825 31.825 0 0110.31-6.967 31.908 31.908 0 0124.42-.122 31.898 31.898 0 0110.38 6.864 31.824 31.824 0 016.97 10.315 31.934 31.934 0 012.49 12.196 31.95 31.95 0 01-9.23 22.604c-38.82 39.206-57.04 77.5-54.17 113.821 4.14 52.288 50.98 87.172 51.46 87.519a31.88 31.88 0 0113.04 29.522 31.916 31.916 0 01-6.2 15.531 31.908 31.908 0 01-13.21 10.253v-.001zM1726.28 513.863c-5.72 2.37-12 3.024-18.09 1.883a31.873 31.873 0 01-16.17-8.308 31.799 31.799 0 01-9.01-15.799 31.844 31.844 0 011.09-18.155c18.86-51.848 19.4-94.253 1.59-126.04-25.63-45.763-82.77-57.86-83.34-57.976a31.946 31.946 0 01-11.52-4.724 31.934 31.934 0 01-8.83-8.772 31.87 31.87 0 01-4.8-11.484 31.802 31.802 0 01-.04-12.448c.8-4.111 2.41-8.024 4.72-11.515a32.016 32.016 0 018.77-8.832 31.936 31.936 0 0111.49-4.802c4.1-.831 8.33-.845 12.44-.042 3.49.672 85.89 17.291 126.37 88.7 27.69 48.833 28.73 109.307 3.1 179.742a31.696 31.696 0 01-6.92 11.16 31.858 31.858 0 01-10.85 7.412zM1783.77 760.273c-39 16.172-86.27 18.418-141.12 6.635a31.92 31.92 0 01-20.09-13.873 31.948 31.948 0 01-4.4-24.018 31.907 31.907 0 0113.88-20.092 31.88 31.88 0 0124.01-4.398c53.94 11.59 96.02 6.321 125.08-15.666 41.82-31.651 45.99-89.9 46.03-90.489a31.884 31.884 0 0110.64-21.89 31.897 31.897 0 0122.98-8.016 31.909 31.909 0 0130.08 33.461c-.19 3.547-5.38 87.447-70.59 137.318a161.936 161.936 0 01-36.5 21.028zM1170.15 648.931c-63.25 26.218-138.71 21.519-224.641-14.044a31.903 31.903 0 01-10.364-52.028 31.906 31.906 0 0134.764-6.925c74.661 30.9 137.241 34.038 186.021 9.362 70.49-35.684 91.88-119.754 92.09-120.6a31.898 31.898 0 0158.22-9.217 31.979 31.979 0 014.31 11.679c.65 4.138.49 8.364-.49 12.438-1.1 4.584-27.88 112.856-124.65 162.279-5 2.56-10.09 4.912-15.26 7.056z"></path>
      <path
        fill={color}
        d="M1311.18 838.663a31.916 31.916 0 01-38.71-11.667 31.932 31.932 0 01-5.29-20.801 31.97 31.97 0 019.09-19.445c38.81-39.211 57.03-77.509 54.14-113.828-4.15-52.289-51-87.164-51.46-87.51a31.859 31.859 0 01-12.84-20.769 31.91 31.91 0 015.6-23.764 31.92 31.92 0 0120.77-12.84c8.35-1.356 16.9.661 23.77 5.607 2.88 2.068 70.79 51.617 77.7 133.412 4.73 55.933-19.6 111.3-72.33 164.575a31.872 31.872 0 01-10.44 7.03zM1109.54 816.877a31.962 31.962 0 01-23.89.175 31.979 31.979 0 01-17.35-16.436c-1.48-3.229-35.84-79.944-1.25-154.387 23.65-50.908 72.26-86.905 144.48-106.98a31.907 31.907 0 0139.28 22.189 31.904 31.904 0 01-22.19 39.283c-53.15 14.778-87.97 38.987-103.5 71.948-22.35 47.455 1.01 100.981 1.25 101.516a31.848 31.848 0 012.87 12.1c.15 4.182-.53 8.354-1.99 12.275a31.696 31.696 0 01-6.54 10.578 31.787 31.787 0 01-10.09 7.266c-.35.164-.71.321-1.08.473zM919.746 651.957a31.902 31.902 0 01-36.66-8.916 31.894 31.894 0 01-2.516-37.645c43.449-68.437 57.29-129.709 41.14-182.117-23.428-76.022-101.868-110.621-102.657-110.96a31.896 31.896 0 01-19.263-29.757 31.912 31.912 0 019.671-22.42 31.895 31.895 0 0122.692-9.015c4.189.061 8.325.946 12.172 2.606 4.327 1.865 106.359 46.915 138.359 150.755 21.896 71.076 5.671 150.177-48.249 235.106a31.758 31.758 0 01-14.689 12.363zM1204.13 344.806a31.916 31.916 0 01-38.21-11.023c-31.93-44.989-66.52-69.534-102.79-72.957-52.22-4.927-94.649 35.2-95.073 35.61a31.9 31.9 0 01-45.113-.553 31.911 31.911 0 01-9.066-22.67 31.908 31.908 0 019.619-22.442c2.536-2.486 63.05-60.831 144.813-53.542 55.91 4.984 106.26 38.5 149.64 99.624a31.934 31.934 0 014.63 27.303 32.007 32.007 0 01-6.87 12.43 31.97 31.97 0 01-11.58 8.22z"></path>
      <path
        fill={color}
        d="M1132.12 501.6c-1.35.562-2.74 1.03-4.16 1.4-3.44.9-84.93 21.52-152.295-25.387-46.065-32.074-73.138-86.162-80.469-160.754a31.9 31.9 0 0128.631-34.869 31.9 31.9 0 0134.869 28.631c5.419 55.151 23.4 93.72 53.424 114.632 42.79 29.791 99.1 16.176 99.66 16.031a31.904 31.904 0 0123.26 2.753 31.93 31.93 0 0115.11 17.895 31.909 31.909 0 01-1.19 23.391 31.86 31.86 0 01-16.84 16.278v-.001z"></path>
      <path
        fill={color}
        d="M1064.06 540.191a31.938 31.938 0 01-29.66-2.757 31.98 31.98 0 01-10.47-11.22 32.008 32.008 0 01-4.02-14.806c-.09-3.551-1.4-87.6 59.74-142.362 41.82-37.454 100.68-51.351 174.96-41.32a31.884 31.884 0 0121.1 12.278 31.912 31.912 0 016.25 23.606 31.93 31.93 0 01-12.28 21.104 31.91 31.91 0 01-23.61 6.241c-54.67-7.383-96.22 1.122-123.48 25.292-39.26 34.792-38.9 93.194-38.89 93.779a31.894 31.894 0 01-5.14 18.127 31.827 31.827 0 01-14.5 12.038z"></path>
      <path
        fill={color}
        d="M1169.43 436.506a32.009 32.009 0 01-15.93 2.241 32.038 32.038 0 01-15.01-5.788c-2.88-2.07-70.78-51.63-77.68-133.425-4.72-55.936 19.63-111.3 72.36-164.564a31.862 31.862 0 0110.32-6.967 31.871 31.871 0 0124.41-.121 31.937 31.937 0 0110.39 6.864 31.943 31.943 0 019.45 22.511c.02 4.189-.78 8.342-2.37 12.22a31.794 31.794 0 01-6.86 10.384c-38.82 39.2-57.04 77.5-54.16 113.817 4.14 52.292 50.98 87.173 51.45 87.521a31.916 31.916 0 0113.05 29.522 31.834 31.834 0 01-6.21 15.531 31.847 31.847 0 01-13.21 10.254zM1344.13 672.296a31.904 31.904 0 01-34.27-6.425 31.8 31.8 0 01-9.01-15.801 31.847 31.847 0 011.09-18.156c18.86-51.845 19.4-94.25 1.59-126.038-25.63-45.763-82.76-57.86-83.34-57.977a31.865 31.865 0 01-11.51-4.725 31.782 31.782 0 01-8.83-8.771 31.881 31.881 0 01-4.81-11.484 31.983 31.983 0 01-.04-12.446c.8-4.112 2.41-8.025 4.73-11.516a31.754 31.754 0 018.77-8.831 31.908 31.908 0 0123.93-4.844c3.49.671 85.89 17.292 126.37 88.7 27.68 48.833 28.72 109.307 3.1 179.742a31.865 31.865 0 01-6.93 11.16 31.818 31.818 0 01-10.84 7.412zM537.589 810.387a31.895 31.895 0 01-36.66-8.915 31.902 31.902 0 01-2.517-37.645c43.449-68.437 57.29-129.709 41.14-182.117-23.428-76.022-101.868-110.621-102.657-110.962a31.903 31.903 0 1125.27-58.586c4.329 1.867 106.361 46.915 138.361 150.756 21.9 71.076 5.669 150.179-48.251 235.108a31.741 31.741 0 01-14.686 12.361zM821.97 503.234a31.912 31.912 0 01-38.206-11.022c-31.933-44.989-66.517-69.538-102.788-72.958-52.221-4.928-94.653 35.2-95.075 35.61a31.913 31.913 0 01-22.672 9.068 31.899 31.899 0 01-28.933-44.469 31.902 31.902 0 017.042-10.265c2.538-2.487 63.051-60.83 144.813-53.541 55.912 4.984 106.259 38.5 149.64 99.624a31.934 31.934 0 014.629 27.303 31.918 31.918 0 01-18.45 20.65z"></path>
      <path
        fill={color}
        d="M749.966 660.03a32.458 32.458 0 01-4.166 1.4c-3.436.9-84.927 21.519-152.291-25.388-46.065-32.074-73.138-86.161-80.469-160.755a31.904 31.904 0 0163.5-6.239c5.419 55.15 23.4 93.721 53.428 114.633 42.784 29.791 99.1 16.176 99.655 16.028a31.903 31.903 0 0138.317 20.666 31.906 31.906 0 01-17.972 39.653l-.002.002z"></path>
      <path
        fill={color}
        d="M681.902 698.62a31.947 31.947 0 01-40.128-13.976 31.942 31.942 0 01-4.026-14.806c-.087-3.551-1.4-87.6 59.747-142.364 41.814-37.452 100.679-51.352 174.956-41.32a31.903 31.903 0 0127.346 35.885 31.9 31.9 0 01-12.279 21.105 31.91 31.91 0 01-23.606 6.241c-54.67-7.382-96.219 1.122-123.485 25.292-39.253 34.792-38.9 93.192-38.892 93.779a31.797 31.797 0 01-19.633 30.164z"></path>
      <path
        fill={color}
        d="M787.272 594.937a32.007 32.007 0 01-30.937-3.545c-2.888-2.07-70.783-51.63-77.685-133.427-4.721-55.933 19.625-111.3 72.36-164.564a31.898 31.898 0 0134.733-7.088 31.9 31.9 0 0117.472 41.597 31.9 31.9 0 01-6.864 10.384c-38.817 39.2-57.04 77.5-54.163 113.817 4.143 52.289 50.98 87.173 51.452 87.518a31.874 31.874 0 0113.05 29.523 31.874 31.874 0 01-19.417 25.785h-.001zM961.971 830.719a31.898 31.898 0 01-43.277-22.224 31.894 31.894 0 011.089-18.155c18.86-51.845 19.395-94.25 1.592-126.038-25.631-45.763-82.767-57.859-83.341-57.977a31.902 31.902 0 1112.238-62.618c3.488.672 85.887 17.292 126.368 88.7 27.68 48.831 28.73 109.307 3.1 179.742a31.808 31.808 0 01-17.769 18.57zM155.433 968.817a31.902 31.902 0 01-36.66-8.916 31.894 31.894 0 01-2.516-37.645c43.447-68.437 57.29-129.711 41.14-182.119-23.428-76.02-101.868-110.619-102.658-110.96a31.9 31.9 0 01-9.593-52.176 31.896 31.896 0 0134.863-6.41c4.327 1.868 106.358 46.915 138.361 150.756 21.9 71.077 5.669 150.177-48.251 235.107a31.755 31.755 0 01-14.686 12.363zM439.813 661.663a31.91 31.91 0 01-38.209-11.021c-31.933-44.989-66.515-69.537-102.786-72.958-52.221-4.927-94.653 35.2-95.075 35.608a31.909 31.909 0 01-22.67 9.068 31.9 31.9 0 01-21.893-54.731c2.538-2.488 63.053-60.831 144.813-53.542 55.912 4.985 106.258 38.5 149.641 99.623a31.933 31.933 0 01-13.821 47.952v.001z"></path>
      <path
        fill={color}
        d="M405.114 753.367a32.004 32.004 0 01-30.935-3.544c-2.888-2.07-70.783-51.63-77.685-133.427-4.721-55.933 19.625-111.3 72.36-164.564a31.914 31.914 0 0122.512-9.456 31.902 31.902 0 0122.829 54.349c-38.817 39.2-57.04 77.5-54.163 113.817 4.142 52.288 50.98 87.172 51.452 87.518a31.876 31.876 0 0113.051 29.523 31.88 31.88 0 01-19.42 25.784h-.001z"></path>
    </svg>
  )
}

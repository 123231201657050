import React from 'react'
import { useSiteMetaData } from '../Hooks/useSiteMetaData'

import { Helmet } from 'react-helmet'
import 'normalize.css'
import './index.scss'
import 'hamburgers/dist/hamburgers.css'
import 'slick-carousel/slick/slick.css'

import Header from '../Header'
import ContactBanner from '../ContactBanner'
import Footer from '../Footer'

import styles from './Layout.module.scss'

const SiteMeta = () => {
  const { metaInformation } = useSiteMetaData()

  const metaDescription = metaInformation.metaDescription
  const metaKeywords = metaInformation.metaKeywords

  return (
    <Helmet
      title="Knas Hemma"
      meta={
        metaInformation && [
          {
            name: 'description',
            content: metaDescription
          },
          {
            name: 'keywords',
            content: metaKeywords
          }
        ]
      }>
      <html lang="sv" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&family=Solway:wght@400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

const TemplateWrapper = props => {
  const { children, theme } = props

  return (
    <div className={styles.Container}>
      <SiteMeta />
      <Header theme={theme ? theme : ''} />
      <div className={styles.Content}>
        <ContactBanner />
        {children}
      </div>
      <Footer theme={theme ? theme : ''} />
    </div>
  )
}

export default TemplateWrapper

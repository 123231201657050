import { graphql, useStaticQuery } from 'gatsby'

export const useHeaderData = () => {
  const { nav } = useStaticQuery(graphql`
    query SiteNav {
      nav: markdownRemark(
        frontmatter: { templateKey: { eq: "settings-menu" } }
      ) {
        frontmatter {
          menuItems {
            path
            label
          }
        }
      }
    }
  `)

  return nav.frontmatter
}

import React from 'react'
import Link from 'gatsby-link'

import { useContactBannerData } from '../Hooks/useContactBannerData'

import styles from './ContactBanner.module.scss'

import IconPhone from './images/phone.svg'
import IconChat from './images/chat.svg'

const ContactBanner = () => {
  const {
    isContactBannerEnabled,
    phoneHeading,
    phoneNumber,
    phoneOpeningHours,
    phoneLink,
    phoneLinkText,
    chatHeading,
    chatOpeningHours,
    chatLink,
    chatLinkText
  } = useContactBannerData()

  if (!isContactBannerEnabled) return null

  return (
    <div className={styles.ContactBanner}>
      <div className={styles.InnerWrapper}>
        <div>
          <img src={IconPhone} alt=""></img>
          <div>
            <h3>
              {phoneHeading} <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </h3>
            {phoneOpeningHours && <p>{phoneOpeningHours}</p>}
            {phoneLink && (
              <p>
                <Link to={phoneLink}>{phoneLinkText || 'Läs mer'}</Link>
              </p>
            )}
          </div>
        </div>
        <div>
          <img src={IconChat} alt=""></img>
          <div>
            <h3>{chatHeading}</h3>
            {chatOpeningHours && <p>{chatOpeningHours}</p>}
            {chatLink && (
              <p>
                <Link to={chatLink}>{chatLinkText || 'Läs mer'}</Link>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactBanner

import React from 'react'
import { useHeaderData } from '../Hooks/useHeaderData'
import { useFooterData } from '../Hooks/useFooterData'
import Link from 'gatsby-link'
import { slide as Menu } from 'react-burger-menu'
import Remark from 'remark'
import html from 'remark-html'
import { Pattern } from '../Layout/pattern'
import Content, { HTMLContent } from '../Content'

import { Logo } from '../Logo'

import styles from './Header.module.scss'

const IconHand = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles.BackgroundHand}
    width="683"
    height="1016"
    fill="none"
    viewBox="0 0 683 1016">
    <path
      fill="#1A1818"
      d="M284.793 935.202c5.849-14.901 12.05-30.118 20.95-43.251 15.263-22.532 35.171-42.137 48.75-65.521 19.454-33.51 35.045-69.275 52.144-104.144 7.486-15.259 15.61-30.315 21.676-46.144 7.623-19.917 14.669-40.222 19.789-60.9 12.417-50.127 40.273-91.491 71.49-131.182 16.26-20.665 33.011-40.977 48.477-62.221 15.458-21.224 29.937-43.19 44.161-65.271 6.437-9.997 10.833-21.285 16.845-31.59 11.582-19.86 25.428-38.613 34.999-59.368 8.443-18.309 13.171-38.516 17.931-58.275 2.781-11.537-4.188-21.563-13.72-26.756-10.705-5.834-23.342-10.406-34.599-.122-8.459 7.734-17.317 15.453-23.993 24.64-14.975 20.609-27.004 43.56-43.284 62.993-39.603 47.276-80.566 93.425-121.783 139.307-14.475 16.113-26.551 15.952-49.42 3.798-14.103-7.503-17.34-16.184-13.674-37.086.734-4.21 1.84-8.352 2.686-12.537 4.405-21.787 8.451-43.654 13.251-65.352 8.962-40.568 12.283-81.726 15.503-123.051 3.179-40.773 9.594-81.284 14.19-121.954 2.832-25.039-12.422-45.65-35.903-49.63-20.913-3.54-34.654 7.147-40.232 34.353-5.482 26.742-7.641 54.168-13.428 80.824-8.041 37.054-19.204 73.444-26.901 110.557-4.718 22.746-5.374 46.318-7.994 69.508-2.906 25.78-5.523 51.606-9.35 77.252-.734 4.9-5.722 10.764-10.262 12.827-2.283 1.038-9.132-4.665-11.932-8.699-12.249-17.69-30.506-23.088-49.518-20.334-18.45 2.675-29.702 18.008-36.744 34.462-7.64 17.848-6.797 17.081-26.703 18.023-9.439.451-20.963 3.787-27.532 10.042-29.266 27.85-42.329 65.082-54.78 102.306-9.323 27.86-18.501 55.787-27.191 83.855-5.038 16.26-11.638 32.667-12.828 49.346-2.239 31.292-.74 62.832-1.386 94.259-.732 35.569-4.885 70.593-17.324 104.342-3.264 8.854-23.053 50.565-15.136 86.878C9.935 977.7 223.368 1021.57 240 1015.29c16.633-6.27 33.463-51.246 44.792-80.088z"></path>
  </svg>
))

const Header = props => {
  const { theme } = props
  const [menuOpen, setMenuOpen] = React.useState(false)
  const { menuItems } = useHeaderData()
  const { frontmatter } = useFooterData()

  const navItems = menuItems
  const contact = frontmatter.navcontact

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const openMenu = () => {
    setMenuOpen(true)
  }

  const toggleMenu = () => {
    buttonRef && buttonRef.current.blur()
    setMenuOpen(!menuOpen)
  }

  const PageContent = HTMLContent || Content
  const convertMarkdownToHtml = markdownString =>
    Remark()
      .use(html)
      .processSync(markdownString?.replace(/\\/g, '  '), (err, file) =>
        err ? { contents: '' } : file
      ).contents

  const MenuButton = React.forwardRef((props, ref) => (
    <button
      className={`${styles.ButtonMenu} hamburger hamburger--squeeze ${
        menuOpen ? 'is-active' : ''
      }
      ${theme ? styles[theme] + 'Btn' : styles.DefaultBtn}`}
      type="button"
      aria-label="Menu"
      aria-controls="navigation"
      ref={ref}
      onClick={() => toggleMenu()}>
      <span className="hamburger-box">
        <span className={`${styles.HamburgerIcon} hamburger-inner`} />
      </span>
    </button>
  ))

  const buttonRef = React.createRef(null)

  return (
    <div className={`${styles.Header}`}>
      <div
        className={`${styles.Container} ${
          theme ? styles[theme] : styles.Default
        }`}>
        <header className={styles.HeaderBlock}>
          {theme === 'Rattish' ? (
            <Pattern className={styles.BackgroundPanel} color="#eba91b" />
          ) : (
            <Pattern className={styles.BackgroundPanel} color="#9c1ba6" />
          )}
          <Link to="/" onClick={() => closeMenu()}>
            <Logo className={styles.Logo} />
          </Link>
          {theme === 'Rattish' ? (
            <Link
              to="/rattish/"
              onClick={() => closeMenu()}
              className={styles.RattishLogo}>
              <IconHand />
              <span>Rätt-ish hemma</span>
            </Link>
          ) : null}
          <nav className="site-nav" aria-label="Huvudmeny">
            <MenuButton ref={buttonRef} />
            <div className={'site-nav-menu-container'}>
              <Menu
                menuClassName={'site-nav-menu'}
                itemListClassName={'site-nav-menu-item-list'}
                width={'100%'}
                right
                isOpen={menuOpen}
                bodyClassName={'site-nav-menu-open'}
                customBurgerIcon={false}
                customCrossIcon={false}>
                {navItems &&
                  navItems.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.path}
                        className="menu-item"
                        onClick={() => closeMenu()}
                        onFocus={() => {
                          !menuOpen && openMenu()
                        }}>
                        {item.label}
                      </Link>
                    )
                  })}
                {/* <div className="menu-footer">
                  <div className="site-nav-menu-footer-container">
                    <footer className="site-nav-menu-footer">
                      {menuOpen && (
                        <div className="footer-sections">
                          <h2 className="footer-title">
                            <Link to="/">Knas hemma</Link>
                          </h2>
                          <div className="footer-contact">
                            <address className="footer-address">
                              {contact && contact.length ? (
                                <PageContent
                                  className="side-item-content"
                                  content={convertMarkdownToHtml(contact)}
                                />
                              ) : null}
                            </address>
                            <div className="footer-social">
                              <ul>
                                <li>
                                  <a href="https://www.facebook.com/knashemma/">
                                    Facebook
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/knashemma/">
                                    Instagram
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="https://www.twitter.com/knashemma/"
                                    onBlur={() => {
                                      closeMenu()
                                    }}>
                                    Twitter
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </footer>
                  </div>
                </div> */}
              </Menu>
            </div>
          </nav>
        </header>
      </div>
    </div>
  )
}
/*content={convertMarkdownToHtml(this.props.contact)}*/

export default Header

import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetaData = () => {
  const { seo } = useStaticQuery(graphql`
    query SiteMetaQuery {
      seo: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          metaInformation {
            metaDescription
            metaKeywords
          }
        }
      }
    }
  `)

  return seo.frontmatter
}
